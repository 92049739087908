(function(app) {
    'use strict';

    app.directive('frtRegexValidity', function () {
        'use strict';

        return {
            require: '?ngModel',
            restrict: 'A',
            link: function ($scope, $element, $attrs, ngModelController) {
                var regex = new RegExp('^' + $attrs.frtRegexValidity + '$');
                var oldSet = ngModelController.$setViewValue;
                ngModelController.$setViewValue = function(value, trigger) {
                    if (regex.test(value)) {
                        oldSet(value, trigger);
                    } else {
                        oldSet(0, trigger);
                    }

                    ngModelController.$render();
                };

                // ngModelController.$formatters.unshift(function (value) {
                //     if (regex.test(value)) {
                //         return ngModelController.$modelValue;
                //     } else {
                //         return 0;
                //     }
                // });

                // ngModelController.$parsers.push(function (value) {
                //
                //     if (regex.test(value)) {
                //         return ngModelController.$viewValue;
                //     } else {
                //         ngModelController.$modelValue = 0;
                //         ngModelController.$render();
                //         return 0;
                //     }
                // });

            }
        };
    });

} (angular.module('app')));